import React, { useEffect, useState, useRef, useMemo } from 'react';
import clsx from 'clsx';
import { useParams, useHistory, matchPath } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MuiChip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import DatabaseExportOutline from 'mdi-material-ui/DatabaseExportOutline';
import FileDocumentPlusOutline from 'mdi-material-ui/FileDocumentPlusOutline';
import FileDocumentMinusOutline from 'mdi-material-ui/FileDocumentMinusOutline';
import FileDocumentEditOutline from 'mdi-material-ui/FileDocumentEditOutline';
import EditIcon from '@material-ui/icons/EditOutlined';
import LabelIcon from '@material-ui/icons/LabelOutlined';

import {
  Alert,
  Button,
  PageHeader,
  Breadcrumbs,
  Tabs,
  Tab,
  SpotIcon,
  Typography,
  Link,
  Icons,
  Menu,
  MenuItem,
  HeaderSkeleton,
  useConfirm,
} from '@passthrough/uikit';

import {
  groupChangeEventsByQuestion,
  flattenLPClosingChangeEvents,
  filterChangeEventsByTypes,
  objectEquals,
} from 'services/utils';
import {
  canApproveStatus,
  canRemindStatuses,
  canSendToCountersignerStatuses,
  canMarkAsFullyExecutedStatuses,
  canResendClosingEmailStatuses,
  canSendToInvestorStatuses,
  canUnapproveStatuses,
  canUndoRequestChangesStatuses,
  signedStatuses,
  PARTIALLY_SIGNED_STATUS,
} from 'components/status/constants';
import { Spinner } from 'components/spinner';
import { LPClosingStatusChip } from 'components/status';
import { ApproveModal } from 'components/approve_modal_v2';
import * as urls from 'services/urls';
import * as api from 'services/api';
import { useToast } from 'services/toast';
import {
  useFund,
  useFundReview,
  useFundEdit,
  useMembers,
} from 'services/providers/fund';
import { useClosing } from 'services/providers/closing';
import { useWhiteLabelConfig } from 'services/providers/theme';

import { SendDialog } from 'pages/fund_closing_overview/send_dialog';
import { SendReminderDialog } from 'pages/fund_closing_overview/send_reminder_dialog';
import { SubdocDataExportModal } from 'pages/fund_closing_overview/modals/data_export/subdoc_data_export';
import { DiligenceDataExportModal } from 'pages/fund_closing_overview/modals/data_export/diligence_data_export';
import { ZipDialog } from 'pages/fund_closing_overview/zip_dialog';
import { ChangeDiligenceApproverDialog } from 'pages/fund_closing_overview/change_diligence_approver_dialog';
import { DocumentUploadPage } from 'components/document_upload/index';
import { RemoveDocumentDialog } from 'pages/fund_closing_overview/remove_document_dialog';
import { MoveToClosingDialog } from 'pages/fund_closing_overview/move_to_closing_dialog';
import { SendClosingEmailDialog } from 'pages/fund_closing_overview/send_closing_email_dialog';
import {
  SIDE_LETTER,
  EXPOSED_SIDE_LETTER_TYPES,
} from 'components/document_upload/constants';
import { InvestorTagChip } from 'components/investor_tagging_chip';
import { InvestorTaggingModal } from 'pages/fund_closing_overview/investor_tagging/index';
import { SendToCountersignersModal } from 'pages/fund_closing_overview/send_to_countersigners_modal_v2';
import { PrepareSignaturePagesModal } from 'pages/fund_closing_overview/prepare_signature_pages_modal';
import { NoteDisplay } from 'pages/fund_closing_overview/overall_investor_note/note_display';
import { EditNoteModal } from 'pages/fund_closing_overview/overall_investor_note/edit_modal';
import {
  canPrepareSignaturePages,
  mustPrepareSignaturePages,
} from 'pages/fund_closing_overview/utils';
import * as riskConstants from 'components/risk_rating/constants';
import { UndoChangeRequestButton } from './undo_change_request_button';
import { RequestChangesModal } from './request_changes_modal';
import { UndoRequestedChangesDialog } from './undo_requested_changes_dialog/index';
import { ReviewTab } from './review_tab';
import { DocumentsTab } from './documents_tab';
import { HistoryTab } from './history_tab';
import { DiligenceV2Tab } from './diligence_v2/index';
import { PeopleTab } from './people_tab';
import { CHANGE_EVENT_TYPES, commentModeLpClosingStatuses } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
  },
  investorTags: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  topDivider: {
    marginTop: theme.spacing(3),
    alignSelf: 'stretch',
  },
  gridContainer: {
    flex: '1 1 auto',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  mainContent: {
    width: `calc(100% - 400px)`,
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRight: 'none',
    },
  },
  sidebar: {
    width: '400px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  mainTabs: {
    height: '50px',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
    },
  },
  sidebarTabs: {
    height: '50px',
    padding: theme.spacing(0, 3),
  },
  primaryIcon: {
    color: theme.palette.primary.main,
  },
  alertContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(1),
    padding: theme.spacing(5, 6, 0, 6),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(5, 3, 0, 3),
    },
  },
  rejectButtonText: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  chipPadding: {
    padding: theme.spacing(0, 0.25),
  },
  spinner: {
    marginTop: '100px',
  },
  headerDescriptionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  headerDescriptionCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  noteContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'center',
  },
}));

export function hasChanges(lpClosing, numNotes) {
  const isFullySigned = lpClosing.status === 'SIGNED';
  const isPartiallySigned = lpClosing.status === 'PARTIALLY_SIGNED';

  const anyUnsigned = lpClosing.docs.some((doc) => {
    const docIsUnsigned =
      doc.signers.length > 0 && doc.signers.every((signer) => !signer.signed);
    return docIsUnsigned;
  });

  const hasNotes = numNotes > 0;
  const requiresSignatures =
    isFullySigned && lpClosing.numSignatureRequired > 0;
  const hasUnsignedJointSigners = isPartiallySigned && anyUnsigned;

  return hasNotes || requiresSignatures || hasUnsignedJointSigners;
}

function getOverallApprovalTooltipText(
  numSignatures,
  hasNotes,
  missingRequiredSubApprovals,
  diligenceOnlyApprovalDisabled,
  hasUnresolvedCommentThreads,
  showSkippingApprovalsWarning,
) {
  if (numSignatures > 0) {
    return "Can't approve while there are documents missing signatures.";
  }

  if (hasNotes) {
    return "Can't approve while there are notes to the investor.";
  }

  if (missingRequiredSubApprovals) {
    return "Can't approve until all workflows are completed.";
  }

  if (diligenceOnlyApprovalDisabled) {
    return "Can't approve until all owners are reviewed.";
  }

  if (hasUnresolvedCommentThreads) {
    return "Can't approve until all comment threads are resolved.";
  }

  if (showSkippingApprovalsWarning) {
    return 'Approve if no further review or changes are required from the investor.';
  }

  return 'Approve if no changes are required from the investor.';
}

const TABS = {
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  DILIGENCE: 'DILIGENCE',
  DOCUMENTS: 'DOCUMENTS',
  PEOPLE: 'PEOPLE',
  ACTIVITY: 'ACTIVITY',
};

// Modal names
const REQUEST_CHANGES_MODAL = 'REQUEST_CHANGES_MODAL';
const UNDO_REQUEST_CHANGES_MODAL = 'UNDO_REQUEST_CHANGES_MODAL';
const SEND_TO_INVESTOR_MODAL = 'SEND_TO_INVESTOR_MODAL';
const SEND_REMINDER_MODAL = 'SEND_REMINDER_MODAL';
const APPROVE_MODAL = 'APPROVE_MODAL';
const SEND_TO_COUNTERSIGNER_MODAL = 'SEND_TO_COUNTERSIGNER_MODAL';
const PREPARE_SIGNATURE_PAGES_MODAL = 'PREPARE_SIGNATURE_PAGES_MODAL';
const EXPORT_MODAL = 'EXPORT_MODAL';
const DILIGENCE_EXPORT_MODAL = 'DILIGENCE_EXPORT_MODAL';
const ZIP_MODAL = 'ZIP_MODAL';
const DILIGENCE_APPROVER_MODAL = 'DILIGENCE_APPROVER_MODAL';
const ADD_SIDE_LETTERS_MODAL = 'ADD_SIDE_LETTERS_MODAL';
const REMOVE_SIDE_LETTERS_MODAL = 'REMOVE_SIDE_LETTERS_MODAL';
const MOVE_CLOSING_MODAL = 'MOVE_CLOSING_MODAL';
const INVESTOR_TAGGING_MODAL = 'INVESTOR_TAGGING_MODAL';
const SEND_CLOSING_EMAIL_MODAL = 'SEND_CLOSING_EMAIL_MODAL';
const EDIT_NOTE_MODAL = 'EDIT_NOTE_MODAL';

export function ReviewPage() {
  const { fundId, closingId, lpClosingId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const confirm = useConfirm();
  const { toast, successToast } = useToast();
  const [fundEdit] = useFundEdit();
  const [fundReview] = useFundReview();
  const [fund] = useFund();
  const [members, , refreshMembers] = useMembers();
  const [subdoc, setSubdoc] = useState(null);
  const [closingData, refreshClosing] = useClosing();
  const { closing } = closingData;

  const [lpClosing, setLpClosing] = useState(null);
  const [diligence, setDiligence] = useState(null);
  const [change, setChange] = useState(0);
  const [diligenceChange, setDiligenceChange] = useState(0);
  const [tab, setTab] = useState(TABS.QUESTIONNAIRE);
  const [sidebarTab, setSidebarTab] = useState(TABS.PEOPLE);
  const [isDiligenceLoading, setIsDiligenceLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [approvalErrorMessages, setApprovalErrorMessages] = useState([]);
  const [approvalLoading, setApprovalLoading] = useState(false);

  const [flattenedChangeEvents, setFlattenedChangeEvents] = useState([]);
  const [changeEventsPerQuestion, setChangeEventsPerQuestion] = useState({});

  const [priorSubmissionAnswers, setPriorSubmissionAnswers] = useState({});
  const [currSubmissionAnswers, setCurrSubmissionAnswers] = useState({});
  const [numChangedAnswers, setNumChangedAnswers] = useState(0);

  const [investorTagGroups, setInvestorTagGroups] = useState(null);
  const [modal, setModal] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);
  const { productName } = useWhiteLabelConfig();

  const isFirstLPSubmission = Object.keys(priorSubmissionAnswers).length === 0;
  const hasDocuments = lpClosing && lpClosing.docs.length > 0;
  const hasQuestionnaire =
    lpClosing &&
    lpClosing.sections
      .filter((s) => s.questions.length > 0)
      .filter((s) => s.type !== 'DILIGENCE').length > 0;

  const hasInvestorTags = lpClosing?.tags?.length > 0;
  const canEdit = fundEdit && !closing?.readOnly;

  const numSignaturesNeeded = useMemo(() => {
    if (!lpClosing) {
      return 0;
    }
    return lpClosing.docs.reduce((acc, doc) => {
      if (doc.mustCountersign) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [lpClosing]);

  const usesQuestionnaireDiligenceSubapprovals =
    lpClosing?.hasDiligence && hasQuestionnaire;

  // Close all modals/menus when switching investors using universal search
  // Otherwise, a modal could remain open for an investor for whom that
  // modal is not relevant (e.g. send to countersigner for an in progress
  // investor)
  useEffect(() => {
    setModal(null);
    setErrorMsg('');
    setApprovalErrorMessages([]);
    setMenuOpen(false);
  }, [lpClosingId]);

  function closeModal() {
    setModal(null);
  }

  function getSubdoc() {
    api.subscriptionDoc({ fundId, closingId }).then((response) => {
      setSubdoc(response.data);
    });
  }

  function getInvestorTagGroups() {
    api.getFundTagData({ fundId }).then((response) => {
      setInvestorTagGroups(response.data);
    });
  }

  function getLpClosing() {
    api.viewLpClosing({ fundId, closingId, lpClosingId }).then((response) => {
      const { draftComments } = response.data;
      const sectionsWithDraftComments = response.data.sections.map((s) => ({
        ...s,
        questions: s.questions.map((q) => ({
          ...q,
          draftComment: draftComments[q.label] || '',
        })),
      }));
      setLpClosing({ ...response.data, sections: sectionsWithDraftComments });
    });
  }

  function* getTabs() {
    if (!lpClosing) {
      return;
    }

    if (hasQuestionnaire) {
      yield { tab: TABS.QUESTIONNAIRE, path: urls.REVIEW_QUESTIONNAIRE_URL };
    }

    if (lpClosing.hasDiligence) {
      yield { tab: TABS.DILIGENCE, path: urls.REVIEW_DILIGENCE_URL };
    }
    if (hasDocuments) {
      yield { tab: TABS.DOCUMENTS, path: urls.REVIEW_DOCUMENTS_URL };
    }
  }

  function getTab() {
    if (!lpClosing) {
      return;
    }

    const tabs = [...getTabs()];
    let foundMatch = false;
    tabs.forEach((tabData) => {
      const { tab: thisTab, path } = tabData;
      const match = matchPath(history.location.pathname, { path });
      if (match) {
        foundMatch = true;
        setTab(thisTab);
      }
    });
    if (!foundMatch) {
      if (hasQuestionnaire) {
        history.replace(
          urls.reviewQuestionnaireUrl({ fundId, closingId, lpClosingId }),
        );
      } else {
        history.replace(
          urls.reviewDiligenceUrl({ fundId, closingId, lpClosingId }),
        );
      }
    }
  }

  function getDiligence() {
    if (!lpClosing || !lpClosing.hasDiligence) {
      return;
    }

    setIsDiligenceLoading(true);
    api
      .lpClosingDiligenceV2({ fundId, lpClosingId })
      .then((response) => {
        setDiligence(response.data);
        setIsDiligenceLoading(false);
      })
      .catch(() => {
        setIsDiligenceLoading(false);
      });
  }

  function getTimestampFromIsoString(isoString) {
    return new Date(isoString).getTime();
  }

  function findPriorAndCurrentLPSubmissionTimes(answerSubmittedEvents) {
    let mostRecentAnswerTime = 0;
    let previousAnswerTime = 0;

    const events = Object.entries(answerSubmittedEvents);

    for (let i = 0; i < events.length; i += 1) {
      const event = events[i][1];

      if (event) {
        // under lp commenting we provide iso date strings
        const eventTime = getTimestampFromIsoString(event.isoSubmissionTime);

        if (eventTime > mostRecentAnswerTime) {
          previousAnswerTime = mostRecentAnswerTime;
          mostRecentAnswerTime = eventTime;
        } else if (
          mostRecentAnswerTime > eventTime &&
          eventTime > previousAnswerTime
        ) {
          previousAnswerTime = eventTime;
        }
      }
    }

    return {
      prevTime: previousAnswerTime,
      currTime: mostRecentAnswerTime,
    };
  }

  function getSubmissionAnswers(answerChangeEvents, submissionTime) {
    return answerChangeEvents.reduce((compiledAnswers, event) => {
      if (!event) {
        return compiledAnswers;
      }
      const currEventTime = getTimestampFromIsoString(event.isoSubmissionTime);

      if (currEventTime === submissionTime) {
        const updatedSubmissionAnswers = compiledAnswers;
        updatedSubmissionAnswers[event.questionId] = event.answer;
        return updatedSubmissionAnswers;
      }

      return compiledAnswers;
    }, {});
  }

  function getPriorAndCurrentLPSubmissionAnswers(allChangeEvents) {
    const allAnswerSubmissionEvents = filterChangeEventsByTypes(
      allChangeEvents,
      [CHANGE_EVENT_TYPES.answer_submitted],
    );

    const { prevTime, currTime } = findPriorAndCurrentLPSubmissionTimes(
      allAnswerSubmissionEvents,
    );

    const currAnswers = getSubmissionAnswers(
      allAnswerSubmissionEvents,
      currTime,
    );
    let priorAnswers = {};

    if (prevTime !== 0) {
      // this scenario can only be encountered when an LP has only signed
      // once, as there is just one set of answer change events at that time
      priorAnswers = getSubmissionAnswers(allAnswerSubmissionEvents, prevTime);
    }

    return { priorAnswers, currAnswers };
  }

  function getMostRecentHistoricalData() {
    if (!lpClosing) {
      return;
    }

    setFlattenedChangeEvents(flattenLPClosingChangeEvents(lpClosing.docs));
  }

  function getStructuredHistoricalData() {
    if (!flattenedChangeEvents) {
      return;
    }

    const { priorAnswers, currAnswers } = getPriorAndCurrentLPSubmissionAnswers(
      flattenedChangeEvents,
    );

    setPriorSubmissionAnswers(priorAnswers);
    setCurrSubmissionAnswers(currAnswers);
    setChangeEventsPerQuestion(
      groupChangeEventsByQuestion(flattenedChangeEvents),
    );
  }

  function calculateNumChangedAnswers() {
    let numChanged = 0;

    if (Object.keys(priorSubmissionAnswers).length === 0) {
      setNumChangedAnswers(numChanged);
      return;
    }

    Object.entries(currSubmissionAnswers).forEach(([qId, currAnswer]) => {
      if (!(qId in priorSubmissionAnswers)) {
        numChanged += 1;
        return;
      }

      const priorAnswer = priorSubmissionAnswers[qId];

      if (!objectEquals(priorAnswer, currAnswer)) {
        numChanged += 1;
      }
    });
    setNumChangedAnswers(numChanged);
  }

  function clearLPClosing() {
    setLpClosing(null);
  }

  useEffect(refreshClosing, [change]);
  useEffect(refreshMembers, [change]);
  useEffect(getSubdoc, [change, fundId, closingId]);
  useEffect(getInvestorTagGroups, [closingId, change]);
  useEffect(getLpClosing, [lpClosingId, change, diligenceChange]);
  useEffect(getTab, [history.location.pathname, lpClosing]);
  useEffect(getDiligence, [lpClosing === null, diligenceChange]);
  useEffect(calculateNumChangedAnswers, [
    currSubmissionAnswers,
    priorSubmissionAnswers,
  ]);

  useEffect(getMostRecentHistoricalData, [lpClosing]);
  useEffect(getStructuredHistoricalData, [flattenedChangeEvents]);
  useEffect(clearLPClosing, [lpClosingId]);

  function crumbs() {
    return (
      <Breadcrumbs
        crumbs={[
          {
            name: fund?.organizationName,
            to: fund?.isOrganizationAdmin
              ? urls.organizationUrl({ organizationId: fund?.organizationId })
              : null,
            skeleton: !fund,
          },
          {
            name: fund?.name,
            to: urls.fundUrl({ fundId: fund?.id }),
            skeleton: !fund,
          },
          {
            name: closing?.name,
            to: urls.closingUrl({ fundId: fund?.id, closingId: closing?.id }),
            skeleton: !closing,
          },
          {
            name: lpClosing?.lpName,
            skeleton: !lpClosing,
          },
        ]}
      />
    );
  }

  if (
    closing === null ||
    lpClosing === null ||
    tab === undefined ||
    fund === null
  ) {
    return (
      <>
        <PageHeader
          crumbs={crumbs()}
          heading={lpClosing?.lpName}
          description={
            lpClosing ? <LPClosingStatusChip lpClosing={lpClosing} /> : null
          }
          skeletons={[
            HeaderSkeleton.crumbs,
            HeaderSkeleton.heading,
            HeaderSkeleton.description,
          ]}
        />
        <div className={classes.spinner}>
          <Spinner />
        </div>
      </>
    );
  }

  function handleChange() {
    setChange((c) => c + 1);
  }

  function handleRequestChanges() {
    handleChange();
    setDiligenceChange((c) => c + 1);
  }

  const isInCommentMode =
    fundReview && commentModeLpClosingStatuses.includes(lpClosing.status);

  const isFullySigned = lpClosing.status === 'SIGNED';
  const isPartiallySigned = lpClosing.status === 'PARTIALLY_SIGNED';

  const isSignedStatus = isFullySigned || isPartiallySigned;
  const isReviewMode = fundReview && isSignedStatus;

  const sections = lpClosing.sections
    .map((s) => ({ ...s, questions: s.questions.filter((q) => q.isAsked) }))
    .filter((s) => s.questions.length > 0);

  const numNotes = sections.reduce(
    (num, s) =>
      num +
      s.questions.reduce((sSum, q) => (q.draftComment ? sSum + 1 : sSum), 0),
    0,
  );

  const canRequestChanges = hasChanges(lpClosing, numNotes);

  const { hasLpRequestedReview } = lpClosing;
  const hasOfflineDoc = lpClosing.docs.some((doc) => doc.isOffline);

  const isDiligenceOnly =
    closing.diligenceEnabled && !closing.subscriptionDocumentName;

  const hasUnresolvedCommentThreads =
    lpClosing.hasUnresolvedLpdocCommentThreads ||
    lpClosing.hasUnresolvedDiligenceCommentThreads;

  const { hasCustomApprovals } = lpClosing;

  const showSkippingApprovalsWarning =
    hasCustomApprovals && !lpClosing?.onFinalState;

  const managedDiligenceEnabled = diligence?.managedDiligenceEnabled;

  const secondActionEnabled = !approvalLoading && numNotes > 0;

  const showNewDocsNeedSignaturesAlert =
    !hasLpRequestedReview &&
    isReviewMode &&
    isFullySigned &&
    lpClosing.numSignatureRequired > 0;

  const showDiligenceRiskRecAlert =
    diligence?.riskRecommendation === riskConstants.CRITICAL_RISK ||
    diligence?.riskRecommendation === riskConstants.HIGH_RISK ||
    diligence?.riskRecommendation === riskConstants.MODERATE_RISK ||
    diligence?.riskRecommendation === riskConstants.NO_RATING ||
    diligence?.riskRecommendation === riskConstants.LOW_RISK;

  const willShowAnAlert =
    errorMsg ||
    hasOfflineDoc ||
    showNewDocsNeedSignaturesAlert ||
    hasLpRequestedReview ||
    showDiligenceRiskRecAlert ||
    diligence?.waivedDiligence;

  function performApprove() {
    setApprovalLoading(true);
    api
      .approveClosingDocs({ fundId, closingId, lpClosingIds: [lpClosingId] })
      .then(() => {
        setErrorMsg('');
        setApprovalErrorMessages([]);
        setApprovalLoading(false);
        successToast(`Approved ${lpClosing.lpName}`);
        handleChange();
        setModal(null);
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setApprovalErrorMessages(error.response.data);
        } else {
          setApprovalErrorMessages([
            'Something went wrong while approving this investor. Please refresh and try again.',
          ]);
        }
        setApprovalLoading(false);
      });
  }

  function handleSend() {
    setModal(SEND_TO_INVESTOR_MODAL);
  }

  function handleSendReminder() {
    setModal(SEND_REMINDER_MODAL);
  }

  function handleMoveClosing(newClosingId) {
    history.replace(history.location.pathname.replace(closingId, newClosingId));
    handleChange();
  }

  function handleSendToCountersigner() {
    setModal(SEND_TO_COUNTERSIGNER_MODAL);
  }

  function handleApprove() {
    setModal(APPROVE_MODAL);
  }

  function handleSendClosingEmail() {
    setModal(SEND_CLOSING_EMAIL_MODAL);
  }

  function approveButton() {
    if (lpClosing.hasDiligence && diligence === null) {
      // still loading
      return (
        <Button variant="primary" disabled>
          Approve
        </Button>
      );
    }

    let missingSubApprovals = false;
    const waitingOnDiligence =
      !diligence?.waivedDiligence && !lpClosing.diligenceApproved;
    if (hasCustomApprovals) {
      // custom approvals don't need questionnaire sub-approvals to approve
      missingSubApprovals =
        usesQuestionnaireDiligenceSubapprovals && waitingOnDiligence;
    } else {
      missingSubApprovals =
        usesQuestionnaireDiligenceSubapprovals &&
        (!lpClosing?.questionnaireApproved || waitingOnDiligence);
    }

    const diligenceOnlyApprovalDisabled =
      isDiligenceOnly && !lpClosing.diligenceApproved;

    const approvalTooltipText = getOverallApprovalTooltipText(
      lpClosing.numSignatureRequired,
      canRequestChanges,
      missingSubApprovals,
      diligenceOnlyApprovalDisabled,
      hasUnresolvedCommentThreads,
      showSkippingApprovalsWarning,
    );

    return (
      <Tooltip
        title={<Typography variant="label">{approvalTooltipText}</Typography>}
      >
        <span>
          <Button
            variant="primary"
            disabled={
              secondActionEnabled ||
              canRequestChanges ||
              !isFullySigned ||
              hasLpRequestedReview ||
              missingSubApprovals ||
              diligenceOnlyApprovalDisabled ||
              hasUnresolvedCommentThreads
            }
            onClick={handleApprove}
            loading={approvalLoading}
            htmlProps={{ 'data-test': 'approve' }}
            startIcon={<Icons.ApprovalOutlined />}
          >
            Approve investor
          </Button>
        </span>
      </Tooltip>
    );
  }

  function rejectButton(inCommentMode = false) {
    if (lpClosing.hasDiligence && diligence === null) {
      // still loading
      return (
        <Button variant="secondary" startIcon={<Icons.SendOutlined />} disabled>
          Request changes
        </Button>
      );
    }

    const buttonText = inCommentMode ? 'Send comments' : 'Request changes';

    return (
      <Tooltip
        title={
          <Typography variant="label">
            {canRequestChanges || hasLpRequestedReview
              ? 'Send comments to the investor'
              : 'Add comments to the investor in order to send them.'}
          </Typography>
        }
      >
        <Button
          variant="secondary"
          startIcon={<Icons.SendOutlined />}
          disabled={!secondActionEnabled}
          onClick={() => {
            setModal(REQUEST_CHANGES_MODAL);
          }}
          htmlProps={{ 'data-test': 'request_changes' }}
        >
          <div className={classes.rejectButtonText}>
            {buttonText}
            {numNotes > 0 ? (
              <MuiChip
                label={numNotes}
                size="small"
                color="primary"
                className={classes.chipPadding}
              />
            ) : null}
          </div>
        </Button>
      </Tooltip>
    );
  }

  function handleUnapprove() {
    confirm({
      description:
        `Undo approval for ${lpClosing.lpName}? Be careful when unapproving investors ` +
        'that have already been countersigned as this will not send any notifications. ' +
        "This action can't be undone.",
      primaryButtonProps: {
        'data-test': 'undo-approval-confirm',
      },
    })
      .then(() => {
        api
          .unapproveClosingDocs({
            fundId,
            closingId,
            lpClosingIds: [lpClosingId],
          })
          .then(() => {
            handleChange();
            toast(`Unapproved ${lpClosing.lpName}.`);
          });
      })
      .catch(() => {});
  }

  function handleDelete() {
    confirm({
      description:
        `Delete ${lpClosing.lpName}? Deleted investors will no longer ` +
        "have access to their documents. This action can't be undone.",
    })
      .then(() => {
        api
          .deleteClosingDocs({ fundId, closingId, lpClosingIds: [lpClosingId] })
          .then(() => {
            history.push(urls.closingUrl({ fundId, closingId }));
            toast(`Deleted ${lpClosing.lpName}.`);
          });
      })
      .catch(() => {});
  }

  function handleMarkAsFullyExecuted() {
    const displayName = lpClosing?.lpName || 'investor';
    const markDescription = `Mark ${displayName} as fully executed?`;
    const emailWarning =
      'This will email the investor a copy of their completed documents.';

    confirm({
      description: closing.disableFullyExecutedEmail
        ? markDescription
        : `${markDescription} ${emailWarning}`,
      confirmationText: 'Mark as fully executed',
      primaryButtonProps: {
        htmlProps: { 'data-test': 'mark_as_fully_executed_confirmation' },
      },
    })
      .then(() => {
        api
          .finalizeClosingDocs({
            fundId,
            closingId,
            lpClosingIds: [lpClosingId],
          })
          .then(() => {
            successToast(`Marked ${displayName} as fully executed.`);
            handleChange();
          });
      })
      .catch(() => {});
  }

  function handleMenu() {
    setMenuOpen(!menuOpen);
  }
  function handleClose() {
    setMenuOpen(false);
  }

  function actionBar() {
    function primaryAction() {
      if (!canEdit) return null;

      const allowMarkingFullyExecuted = !closing.hasCountersigning;

      // first signer missing from any doc means we need to send for signature
      const firstSignerMissing = lpClosing.docs.some((doc) => {
        if (doc.signed) return false;
        const lpSigners = doc.signers.filter(
          (signer) => !signer.isCountersigner,
        );
        return lpSigners.length > 0;
      });
      const needsSendForSignature =
        lpClosing.numSignatureRequired > 0 &&
        lpClosing.status === PARTIALLY_SIGNED_STATUS &&
        firstSignerMissing &&
        !lpClosing.asksForFirstSignerEmail;
      const canSendReminder =
        canRemindStatuses.includes(lpClosing.status) && !needsSendForSignature;

      if (canSendToInvestorStatuses.includes(lpClosing.status)) {
        return (
          <Button
            variant="secondary"
            startIcon={<Icons.SendOutlined />}
            onClick={handleSend}
            disabled={secondActionEnabled}
          >
            Send to investor
          </Button>
        );
      }
      if (canSendReminder) {
        return (
          <Button
            variant="secondary"
            startIcon={<Icons.SendOutlined />}
            onClick={handleSendReminder}
            disabled={secondActionEnabled}
          >
            Send reminder
          </Button>
        );
      }
      if (signedStatuses.includes(lpClosing.status)) {
        const canApprove =
          canApproveStatus.includes(lpClosing.status) &&
          lpClosing.numSignatureRequired === 0;
        if (canApprove) {
          return approveButton();
        }
        if (secondActionEnabled) {
          return (
            <Tooltip
              title={
                <Typography variant="label">
                  Unsigned document will be sent to investor with change
                  requests
                </Typography>
              }
            >
              <span>
                <Button
                  variant="primary"
                  startIcon={<Icons.SendOutlined />}
                  onClick={() => setModal(REQUEST_CHANGES_MODAL)}
                  disabled
                >
                  Send for signature
                </Button>
              </span>
            </Tooltip>
          );
        }
        return (
          <Button
            variant="primary"
            startIcon={<Icons.SendOutlined />}
            onClick={() => setModal(REQUEST_CHANGES_MODAL)}
          >
            Send for signature
          </Button>
        );
      }
      if (
        !allowMarkingFullyExecuted &&
        canSendToCountersignerStatuses.includes(lpClosing.status) &&
        !mustPrepareSignaturePages(lpClosing)
      ) {
        return (
          <Button
            variant="secondary"
            startIcon={<Icons.SendOutlined />}
            onClick={handleSendToCountersigner}
            disabled={secondActionEnabled}
            data-test="send-to-countersigner-review-page"
          >
            Send to countersigner
          </Button>
        );
      }
      if (
        allowMarkingFullyExecuted &&
        canMarkAsFullyExecutedStatuses.includes(lpClosing.status)
      ) {
        return (
          <Button
            variant="secondary"
            startIcon={<Icons.SendOutlined />}
            onClick={handleMarkAsFullyExecuted}
            htmlProps={{ 'data-test': 'mark_as_fully_executed' }}
            disabled={secondActionEnabled}
          >
            Mark as fully executed
          </Button>
        );
      }

      if (canResendClosingEmailStatuses.includes(lpClosing.status)) {
        return (
          <Button
            variant="secondary"
            startIcon={<Icons.SendOutlined />}
            onClick={handleSendClosingEmail}
            disabled={secondActionEnabled}
          >
            Send closing email
          </Button>
        );
      }

      return null;
    }

    function menuItems() {
      return [
        canEdit &&
        lpClosing.customActions.length > 0 &&
        canApproveStatus.includes(lpClosing.status) ? (
          <MenuItem
            key="Approve investor"
            text="Approve investor"
            subtext="Skip remaining steps in the approval flow"
            icon={
              <Icons.ApprovalOutlined
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={handleApprove}
            divider
          />
        ) : null,
        hasQuestionnaire ? (
          <MenuItem
            key="Export data"
            text="Export data"
            icon={
              <DatabaseExportOutline
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={() => setModal(EXPORT_MODAL)}
          />
        ) : null,
        lpClosing.hasDiligence ? (
          <MenuItem
            key="Export diligence data"
            text="Export diligence data"
            icon={
              <DatabaseExportOutline
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={() => setModal(DILIGENCE_EXPORT_MODAL)}
          />
        ) : null,
        <MenuItem
          key="Download documents (.zip)"
          text="Download documents (.zip)"
          icon={
            <Icons.FileDownloadOutlined
              fontSize="small"
              className={classes.primaryIcon}
            />
          }
          divider={canEdit}
          onClick={() => setModal(ZIP_MODAL)}
        />,
        canEdit ? (
          <MenuItem
            key="Add or edit note"
            text="Add or edit note"
            onClick={() => setModal(EDIT_NOTE_MODAL)}
            icon={
              <Icons.StickyNote2Outlined
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
          />
        ) : null,
        canEdit ? (
          <MenuItem
            key="Tag investor"
            text="Tag investor"
            onClick={() => setModal(INVESTOR_TAGGING_MODAL)}
            icon={
              <LabelIcon fontSize="small" className={classes.primaryIcon} />
            }
          />
        ) : null,
        canEdit ? (
          <MenuItem
            key="Add document"
            text="Add document"
            icon={
              <FileDocumentPlusOutline
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={() => setModal(ADD_SIDE_LETTERS_MODAL)}
          />
        ) : null,
        canEdit ? (
          <MenuItem
            key="Remove document"
            text="Remove document"
            icon={
              <FileDocumentMinusOutline
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            disabled={
              lpClosing.sideletters.length + lpClosing.offerings.length === 0
            }
            onClick={() => setModal(REMOVE_SIDE_LETTERS_MODAL)}
          />
        ) : null,
        canEdit ? (
          <MenuItem
            data-test="prepare-signature-pages-action"
            key="Prepare signature pages"
            text="Prepare signature pages"
            onClick={() => setModal(PREPARE_SIGNATURE_PAGES_MODAL)}
            disabled={!canPrepareSignaturePages(lpClosing)}
            icon={<FileDocumentEditOutline fontSize="small" color="primary" />}
          />
        ) : null,
        canEdit && canUnapproveStatuses.includes(lpClosing.status) ? (
          <MenuItem
            data-test="undo-approval-action"
            key="Undo approval"
            text="Undo approval"
            icon={
              <Icons.Undo fontSize="small" className={classes.primaryIcon} />
            }
            onClick={handleUnapprove}
          />
        ) : null,
        canEdit ? (
          <MenuItem
            key="Move to new closing"
            text="Move to new closing"
            icon={
              <Icons.DriveFileMoveOutlined
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={() => setModal(MOVE_CLOSING_MODAL)}
          />
        ) : null,
        canEdit && lpClosing.hasDiligence ? (
          <MenuItem
            key="Change diligence reviewer"
            text="Change diligence reviewer"
            icon={
              <Icons.EditOutlined
                fontSize="small"
                className={classes.primaryIcon}
              />
            }
            onClick={() => setModal(DILIGENCE_APPROVER_MODAL)}
          />
        ) : null,
        canEdit ? (
          <MenuItem
            key="Delete"
            text="Delete"
            destructive
            icon={<Icons.DeleteOutlined fontSize="small" />}
            onClick={handleDelete}
          />
        ) : null,
      ].filter(Boolean);
    }

    return (
      <>
        {primaryAction()}
        {(isInCommentMode || isReviewMode) && canEdit
          ? rejectButton(isInCommentMode)
          : null}
        {canUndoRequestChangesStatuses.includes(lpClosing.status) && canEdit ? (
          <UndoChangeRequestButton
            setUndoRequestChangesDialogOpen={() =>
              setModal(UNDO_REQUEST_CHANGES_MODAL)
            }
            disabled={!lpClosing.canUndoChangeRequest}
          />
        ) : null}
        {mustPrepareSignaturePages(lpClosing) ? (
          <Button
            variant="secondary"
            onClick={() => setModal(PREPARE_SIGNATURE_PAGES_MODAL)}
            startIcon={<FileDocumentEditOutline />}
            data-test="prepare-signature-pages"
          >
            Prepare signature pages
          </Button>
        ) : null}
        {customApprovalActions()}
        <Button
          variant="icon"
          onClick={handleMenu}
          ref={anchorRef}
          data-test="more-actions"
          aria-label="More actions"
        >
          <Icons.MoreVert />
        </Button>
        <Menu
          anchorEl={anchorRef.current}
          open={menuOpen}
          onClose={handleClose}
        >
          {menuItems()}
        </Menu>
        <SubdocDataExportModal
          open={modal === EXPORT_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
        />
        <DiligenceDataExportModal
          open={modal === DILIGENCE_EXPORT_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
        />
        <ZipDialog
          open={modal === ZIP_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
        />
        <DocumentUploadPage
          open={modal === ADD_SIDE_LETTERS_MODAL}
          onChange={handleChange}
          handleClose={closeModal}
          hasFirstSigner={closing.hasFirstSigner}
          hasSecondSigner={closing.hasSecondSigner}
          hasThirdSigner={closing.hasThirdSigner}
          hasFourthSigner={closing.hasFourthSigner}
          hasFifthSigner={closing.hasFifthSigner}
          hasSixthSigner={closing.hasSixthSigner}
          hasFirstCounterSigner={closing.numberOfCountersigners >= 1}
          hasSecondCounterSigner={closing.numberOfCountersigners >= 2}
          hasThirdCounterSigner={closing.numberOfCountersigners >= 3}
          lpClosings={[lpClosing]}
          dataModelType={SIDE_LETTER}
          docTypesToDisplay={EXPOSED_SIDE_LETTER_TYPES}
          allowInvestorTypeInEditMode={closing.hasInvestorTypeQuestion}
        />
        <RemoveDocumentDialog
          open={modal === REMOVE_SIDE_LETTERS_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
          onChange={handleChange}
        />
        <MoveToClosingDialog
          open={modal === MOVE_CLOSING_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
          onChange={handleMoveClosing}
          members={members}
        />
        <ChangeDiligenceApproverDialog
          open={modal === DILIGENCE_APPROVER_MODAL}
          defaultDiligenceApproverId={closing.defaultDiligenceApproverId}
          investors={[lpClosing]}
          onChange={handleChange}
          handleClose={closeModal}
          members={members}
        />
      </>
    );
  }

  function performAction(action) {
    setApprovalLoading(true);
    api
      .performCustomAction({ fundId, lpClosingId, actionId: action.id })
      .then(() => {
        setErrorMsg('');
        setApprovalLoading(false);
        handleChange();
        successToast(
          `${action.actionText} on ${lpClosing.lpName} was successful`,
        );
      })
      .catch(() => {
        setApprovalLoading(false);
      });
  }

  function customApprovalActions() {
    if (!isReviewMode || lpClosing.customActions.length === 0) {
      return null;
    }

    return (
      <>
        {lpClosing.customActions.map((action) => (
          <Button
            variant="secondary"
            onClick={() => {
              performAction(action);
            }}
            loading={approvalLoading}
          >
            {action.actionText}
          </Button>
        ))}
      </>
    );
  }

  function tabIcons(enabled, complete) {
    return (
      <>
        {enabled ? (
          <>
            {complete ? (
              <SpotIcon variant="success" size="small">
                <Icons.Check fontSize="small" />
              </SpotIcon>
            ) : (
              <SpotIcon variant="neutral" size="small">
                <Icons.HourglassEmpty fontSize="small" />
              </SpotIcon>
            )}
          </>
        ) : null}
      </>
    );
  }

  function tags() {
    return (
      <div className={classes.investorTags}>
        {hasInvestorTags ? (
          lpClosing?.tags.map((t) => (
            <InvestorTagChip key={t.id} labelStr={t.name} />
          ))
        ) : (
          <Typography variant="label" color="text.secondary">
            No investor tags
          </Typography>
        )}
        {fundEdit ? (
          <Button
            variant="icon"
            onClick={() => setModal(INVESTOR_TAGGING_MODAL)}
            aria-label="Edit tags"
          >
            <EditIcon />
          </Button>
        ) : null}
      </div>
    );
  }

  function headerDescription() {
    const useColLayout = Boolean(hasInvestorTags || lpClosing?.mostRecentNote);
    return (
      <div
        className={clsx({
          [classes.headerDescriptionRow]: !useColLayout,
          [classes.headerDescriptionCol]: useColLayout,
        })}
      >
        {tags()}
        <div className={classes.noteContainer}>
          <NoteDisplay
            note={lpClosing?.mostRecentNote}
            createdAt={lpClosing?.mostRecentNoteCreatedAt}
          />
          {fundEdit ? (
            <Button
              variant="icon"
              onClick={() => setModal(EDIT_NOTE_MODAL)}
              aria-label="Edit note"
            >
              <EditIcon />
            </Button>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment key={`${closingId}-${lpClosingId}`}>
      <PageHeader
        crumbs={crumbs()}
        heading={lpClosing.lpName}
        description={headerDescription()}
        metadata={<LPClosingStatusChip lpClosing={lpClosing} />}
        actionButtons={actionBar()}
      />
      <Container component="main" maxWidth={false} className={classes.root}>
        {willShowAnAlert ? (
          <div className={classes.alertContainer}>
            {errorMsg !== '' ? (
              <Alert severity="error">{errorMsg}</Alert>
            ) : null}

            {hasOfflineDoc ? (
              <Alert severity="warning" title="Signed offline">
                A document from this investor was signed offline and then
                uploaded to {productName}. It's possible that the answers here
                don't match the contents of their uploaded file.
              </Alert>
            ) : null}

            {showNewDocsNeedSignaturesAlert ? (
              <Alert severity="info" title="New document(s) require signature">
                New document(s) have been added since this investor has signed.
                Send the documents back to the investor for signature.
              </Alert>
            ) : null}

            {hasLpRequestedReview ? (
              <Alert
                severity="info"
                title={`${lpClosing.numSignatureRequired} document${
                  lpClosing.numSignatureRequired > 1 ? 's' : ''
                } still require${
                  lpClosing.numSignatureRequired > 1 ? '' : 's'
                } signature`}
              >
                This investor has requested a review without signing their
                documents. Send the documents back to the investor for
                signature.
              </Alert>
            ) : null}

            {diligence?.waivedDiligence ? (
              <Alert severity="info">
                Diligence is optional for this investor.
              </Alert>
            ) : null}

            {diligence?.riskRecommendation === riskConstants.CRITICAL_RISK ? (
              <Alert severity="error">
                The recommended risk rating for this investor is critical.
              </Alert>
            ) : null}

            {diligence?.riskRecommendation === riskConstants.HIGH_RISK ? (
              <Alert severity="error">
                The recommended risk rating for this investor is high.
              </Alert>
            ) : null}

            {diligence?.riskRecommendation === riskConstants.MODERATE_RISK ? (
              <Alert severity="warning">
                The recommended risk rating for this investor is moderate.
              </Alert>
            ) : null}

            {diligence?.riskRecommendation === riskConstants.LOW_RISK ? (
              <Alert severity="success">
                The recommended risk rating for this investor is low.
              </Alert>
            ) : null}

            {diligence?.riskRecommendation === riskConstants.NO_RATING ? (
              <Alert severity="warning">
                The risk of the investor is unable to be assessed.
              </Alert>
            ) : null}
          </div>
        ) : null}
        <Divider className={classes.topDivider} />
        <div className={classes.gridContainer}>
          <div className={classes.mainContent}>
            <Tabs
              value={tab}
              onChange={(e, v) => {
                setTab(v);
              }}
              htmlProps={{
                'aria-label': 'Review tabs',
                className: classes.mainTabs,
              }}
            >
              {hasQuestionnaire ? (
                <Tab
                  startIcon={tabIcons(
                    lpClosing.hasDiligence && !lpClosing.hasCustomApprovals,
                    lpClosing.questionnaireApproved,
                  )}
                  label="Questionnaire"
                  value={TABS.QUESTIONNAIRE}
                  htmlProps={{
                    'aria-controls': 'review-tabs-0',
                    'data-test': 'questionnaire_tab',
                  }}
                  component={Link}
                  href={urls.reviewQuestionnaireUrl({
                    fundId,
                    closingId,
                    lpClosingId,
                  })}
                />
              ) : null}
              {lpClosing.hasDiligence ? (
                <Tab
                  startIcon={tabIcons(
                    hasQuestionnaire && !lpClosing.hasCustomApprovals,
                    lpClosing.diligenceApproved &&
                      (lpClosing.isPostApproval ||
                        !lpClosing.hasUnresolvedDiligenceCommentThreads),
                  )}
                  label="Diligence"
                  value={TABS.DILIGENCE}
                  htmlProps={{
                    'aria-controls': 'review-tabs-3',
                    'data-test': 'diligence_v2_tab',
                  }}
                  component={Link}
                  href={urls.reviewDiligenceUrl({
                    fundId,
                    closingId,
                    lpClosingId,
                  })}
                />
              ) : null}
              {hasDocuments ? (
                <Tab
                  label="Documents"
                  value={TABS.DOCUMENTS}
                  htmlProps={{
                    'aria-controls': 'review-tabs-1',
                    'data-test': 'documents-tab',
                  }}
                  component={Link}
                  href={urls.reviewDocumentsUrl({
                    fundId,
                    closingId,
                    lpClosingId,
                  })}
                  endIcon={
                    numSignaturesNeeded ? (
                      <MuiChip
                        label={numSignaturesNeeded}
                        size="small"
                        color="secondary"
                        className={classes.chipPadding}
                      />
                    ) : null
                  }
                />
              ) : null}
            </Tabs>

            {tab === TABS.QUESTIONNAIRE ? (
              <ReviewTab
                fund={fund}
                lpClosing={lpClosing}
                onChange={handleChange}
                changeEventsPerQuestion={changeEventsPerQuestion}
                priorAnswersPerQuestion={priorSubmissionAnswers}
                numChangedAnswers={numChangedAnswers}
                enableAnswerFormatting={!isFirstLPSubmission}
                usesQuestionnaireDiligenceSubapprovals={
                  usesQuestionnaireDiligenceSubapprovals
                }
                hasCustomApprovals={hasCustomApprovals}
              />
            ) : null}

            {tab === TABS.DILIGENCE ? (
              <DiligenceV2Tab
                diligenceId={diligence?.id}
                canSkipDiligence={diligence?.canSkipDiligence}
                canWaiveDiligence={diligence?.canWaiveDiligence}
                canRequireDiligence={diligence?.canRequireDiligence}
                investorName={lpClosing?.lpName}
                fundName={fund?.name}
                fundId={fund?.id}
                lpClosingId={lpClosingId}
                showSpinner={!diligence && isDiligenceLoading}
                pendingUpdate={isDiligenceLoading}
                docCollectionMode={diligence?.docCollectionMode}
                managedDiligenceEnabled={managedDiligenceEnabled}
                jurisdiction={diligence?.jurisdiction}
                diligenceQuestions={diligence?.questions || []}
                diligenceApprovers={lpClosing.diligenceApprovers}
                refreshDiligenceData={() => {
                  setDiligenceChange((c) => c + 1);
                }}
                closingStatus={lpClosing.status}
                areSearchesReady={diligence?.areSearchesReady}
                riskRecommendation={diligence?.riskRecommendation}
                isPostApproval={lpClosing.isPostApproval}
                isDiligenceOnly={!closing.subscriptionDocumentName}
              />
            ) : null}

            {tab === TABS.DOCUMENTS ? (
              <DocumentsTab
                fundName={fund?.name}
                lpClosing={lpClosing}
                closing={closing}
                onChange={handleChange}
                changeEventsPerQuestion={changeEventsPerQuestion}
                priorAnswersPerQuestion={priorSubmissionAnswers}
                enableAnswerFormatting={!isFirstLPSubmission}
                openDocumentUploadPage={() => setModal(ADD_SIDE_LETTERS_MODAL)}
              />
            ) : null}
          </div>

          <div className={classes.sidebar}>
            <Tabs
              value={sidebarTab}
              onChange={(e, v) => {
                setSidebarTab(v);
              }}
              htmlProps={{
                'aria-label': 'Review sidebar tabs',
                className: classes.sidebarTabs,
              }}
              className={classes.sidebarTabs}
            >
              <Tab label="People" value={TABS.PEOPLE} />
              <Tab label="Activity" value={TABS.ACTIVITY} />
            </Tabs>
            {sidebarTab === TABS.PEOPLE ? (
              <PeopleTab
                lpClosing={lpClosing}
                fundId={fundId}
                closingId={closingId}
              />
            ) : null}

            {sidebarTab === TABS.ACTIVITY ? (
              <HistoryTab change={change} />
            ) : null}
          </div>
        </div>

        <RequestChangesModal
          open={modal === REQUEST_CHANGES_MODAL}
          lpClosingStatus={lpClosing.status}
          isInCommentMode={isInCommentMode}
          handleChange={handleRequestChanges}
          handleClose={closeModal}
          numNotes={numNotes}
          numSignatureRequired={lpClosing.numSignatureRequired}
          lpName={lpClosing.lpName}
          lpUsers={lpClosing.lpUsers}
          docs={lpClosing.docs}
        />

        <UndoRequestedChangesDialog
          open={modal === UNDO_REQUEST_CHANGES_MODAL}
          lpName={lpClosing.lpName}
          numSignatureRequired={lpClosing.numSignatureRequired}
          handleClose={closeModal}
          onChange={handleChange}
        />

        <ApproveModal
          investors={[lpClosing]}
          open={modal === APPROVE_MODAL}
          handleClose={closeModal}
          onApproveClick={performApprove}
          approvalLoading={approvalLoading}
          showSkippingApprovalsWarning={showSkippingApprovalsWarning}
          errorMsgs={approvalErrorMessages}
        />

        <SendDialog
          open={modal === SEND_TO_INVESTOR_MODAL}
          investors={[lpClosing]}
          diligenceEnabled={closing.diligenceEnabled}
          onChange={handleChange}
          handleClose={closeModal}
          closing={closing}
          members={members}
          subscriptionDoc={subdoc}
        />

        <SendReminderDialog
          open={modal === SEND_REMINDER_MODAL}
          investors={[lpClosing]}
          onChange={handleChange}
          handleClose={closeModal}
        />

        <SendClosingEmailDialog
          open={modal === SEND_CLOSING_EMAIL_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
          onChange={handleChange}
        />

        <SendToCountersignersModal
          open={modal === SEND_TO_COUNTERSIGNER_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
          onChange={handleChange}
          closing={closing}
          disableFullyExecutedEmail={closing.disableFullyExecutedEmail}
          subdoc={subdoc}
          members={members}
        />

        <InvestorTaggingModal
          open={modal === INVESTOR_TAGGING_MODAL}
          selectedInvestors={[lpClosing]}
          investorTagGroups={investorTagGroups}
          handleClose={closeModal}
          refreshTaggingData={handleChange}
        />

        <PrepareSignaturePagesModal
          open={modal === PREPARE_SIGNATURE_PAGES_MODAL}
          investor={lpClosing}
          handleClose={closeModal}
          onChange={handleChange}
          hasFirstCounterSigner={closing.numberOfCountersigners >= 1}
          hasSecondCounterSigner={closing.numberOfCountersigners >= 2}
          hasThirdCounterSigner={closing.numberOfCountersigners >= 3}
        />

        <EditNoteModal
          open={modal === EDIT_NOTE_MODAL}
          investors={[lpClosing]}
          handleClose={closeModal}
          onChange={handleChange}
        />
      </Container>
    </React.Fragment>
  );
}
